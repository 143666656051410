// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LanguageDropDown.vue render when created  1`] = `
<div
  class="lx-dropdown"
>
  <div
    class="dropdown b-dropdown btn-group"
    id="__BVID__4"
  >
    <!---->
    <button
      aria-expanded="false"
      aria-haspopup="menu"
      class="btn dropdown-toggle btn-link dropdown-toggle-no-caret"
      id="__BVID__4__BV_toggle_"
      type="button"
    >
      <div>
        <div
          class="lx-dropdown__btn-text"
        >
          <span
            class="lx-dropdown__text-left"
          >
            Language
          </span>
           
          <span
            class="lx-dropdown__text-right"
          >
            <span>
              EN
            </span>
             
            <svg
              aria-hidden="true"
              aria-label="blank"
              class="bi-blank lx-dropdown__icon b-icon bi"
              fill="currentColor"
              focusable="false"
              height="1em"
              role="img"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g />
            </svg>
          </span>
        </div>
      </div>
    </button>
    <ul
      aria-labelledby="__BVID__4__BV_toggle_"
      class="dropdown-menu"
      role="menu"
      tabindex="-1"
    >
       
      <li
        class=""
        role="presentation"
      >
        <a
          class="dropdown-item"
          href="javascript:;"
          role="menuitem"
          target="_self"
        >
          Arabic
        </a>
      </li>
      <li
        class=""
        role="presentation"
      >
        <a
          class="dropdown-item"
          href="javascript:;"
          role="menuitem"
          target="_self"
        >
          Chinese
        </a>
      </li>
      <li
        class="lx-dropdown__selected-item"
        role="presentation"
      >
        <a
          class="dropdown-item"
          href="javascript:;"
          role="menuitem"
          target="_self"
        >
          English
        </a>
      </li>
      <li
        class=""
        role="presentation"
      >
        <a
          class="dropdown-item"
          href="javascript:;"
          role="menuitem"
          target="_self"
        >
          French
        </a>
      </li>
      <li
        class=""
        role="presentation"
      >
        <a
          class="dropdown-item"
          href="javascript:;"
          role="menuitem"
          target="_self"
        >
          Spanish
        </a>
      </li>
      <li
        class=""
        role="presentation"
      >
        <a
          class="dropdown-item"
          href="javascript:;"
          role="menuitem"
          target="_self"
        >
          Russian
        </a>
      </li>
    </ul>
  </div>
</div>
`;
